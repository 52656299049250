
import { PropType } from "vue";

type Type = string | number;
type DefaultValue = string | number;

export default {
  name: "InputBasicNew",
  components: {},
  props: {
    label: {
      type: String as PropType<string>,
      default: "",
    },
    subLabel: {
      type: String,
      required: false,
    },
    type: {
      type: [String, Number] as PropType<Type>,
      default: "text",
    },
    defaultValue: {
      type: [String, Number] as PropType<DefaultValue>,
      default: "",
    },
    placeholder: {
      type: String as PropType<string>,
      default: "",
    },
    maxLength: {
      type: Number,
      required: false,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    errorMessage: {
      type: String as PropType<string>,
      default: "",
    },
    caption: {
      type: String,
      required: false,
    },
    padding: {
      type: String,
      default: "14px 16px",
    },
    required: {
      type: Boolean,
      default: false,
    },
    min: {
      type: [String, Number],
      default: null,
    },
    max: {
      type: [String, Number],
      default: null,
    },
  },
  emits: ["updateData"],
  setup(props, { emit }) {
    const actions = {
      updateData: (e) => {
        emit("updateData", e.target.value);
      },
    };

    return { actions };
  },
};
